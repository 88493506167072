import { getHeaders } from "#shared/components/api/utils";
import { QUERY_TAGS } from "#src/constants/query";
import env from "#src/env";
import { appBaseQuery } from "#src/features/common/baseQuery";
import { GetOauthImportsForChannelResponse, OauthImportItem } from "#src/types/oauth";
import createApi from "../../../createApi";

export const oauthImportApi = createApi({
  reducerPath: "oauthImport",
  baseQuery: appBaseQuery({
    baseUrl: `${env.VITE_API_BASE_URL}/community/oauth-email-aggregate/`,
  }),
  tagTypes: [QUERY_TAGS.OauthImport, QUERY_TAGS.OauthChannelImports],
  endpoints: (builder) => ({
    fetchOauthImportsForChannel: builder.query<GetOauthImportsForChannelResponse, number>(
      {
        query: (channelId) => ({
          url: `channel/${channelId}`,
          method: "GET",
          headers: getHeaders(),
          credentials: "include",
        }),
        providesTags: (_result, _error, channelId) => [
          { type: QUERY_TAGS.OauthChannelImports, id: channelId },
        ],
      }
    ),
    fetchOauthImportStatus: builder.query<OauthImportItem, string>({
      query: (id) => ({
        url: `${id}`,
        method: "GET",
        headers: getHeaders(),
        credentials: "include",
      }),
      providesTags: (_result, _error, id) => [
        { type: QUERY_TAGS.OauthImport, id },
        QUERY_TAGS.OauthImport,
      ],
    }),
  }),
});

export const { useFetchOauthImportStatusQuery, useFetchOauthImportsForChannelQuery } =
  oauthImportApi;
