import React, { PropsWithChildren } from "react";

import {
  DehydratedState,
  HydrationBoundary,
  QueryClient,
  QueryClientProvider,
} from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { hydrateRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";

import { defaultTanstackQueryConfig } from "#src/utils/tanstackQueryConfig";
import { RootState, Store, getStore } from "../store.js";
import { PageContext } from "../types/types";
import SentryContextUpdater from "./SentryContextUpdater";
import { getInitialClientState } from "./getInitialClientState";
import { setupClientAxiosHeaders } from "./headers/setupClientAxiosHeaders";
import "./sentry.ts";
import { initializeSessionStorage } from "./storage/initializeSessionStorage";

let store: Store;
export { render, store as rootStore };

async function render(pageContext: PageContext) {
  const { Page, pageProps } = pageContext;
  store = getStore(getInitialClientState(pageContext.initialStoreState as RootState));
  setupClientAxiosHeaders(pageContext);
  initializeSessionStorage();

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const container = document.getElementById("page-view")!;
  const page = (
    <QueryClientWrapper dehydratedState={pageContext.dehydratedState}>
      <Provider store={store}>
        <SentryContextUpdater />
        <BrowserRouter>
          <Page {...pageProps} />
        </BrowserRouter>
      </Provider>
    </QueryClientWrapper>
  );
  hydrateRoot(container, page);
}

const QueryClientWrapper = (
  props: PropsWithChildren<{ dehydratedState: DehydratedState }>
) => {
  const [queryClient] = React.useState(() => new QueryClient(defaultTanstackQueryConfig));

  return (
    <QueryClientProvider client={queryClient}>
      <HydrationBoundary state={props.dehydratedState}>
        {props.children}
        <ReactQueryDevtools initialIsOpen={false} buttonPosition="bottom-left" />
      </HydrationBoundary>
    </QueryClientProvider>
  );
};
