import { useEffect } from "react";

import * as Sentry from "@sentry/browser";
import { useSelector } from "react-redux";

import { selectUser } from "#src/features/user/userSlice";

export default function SentryContextUpdater() {
  const user = useSelector(selectUser);

  useEffect(() => {
    if (user.isLoggedIn && user.profile.userRef) {
      Sentry.setUser({ id: user.profile.userRef, username: user.profile.displayName });
    } else {
      Sentry.setUser(null);
    }
  }, [user]);

  return null;
}
